import swal from "sweetalert";

const login = () => {
    return new Promise(resolve => {
        var settings = {
            "url": "/Mandaluyong/OnlineMarket/login",
            "method": "POST",
            "headers": {
                /* "Content-Type": "application/json" */
            },
            "data": {
                "user_id": $('#user_id').val(),
                "password": $('#password').val(),
            }
        };
        $.ajax(settings)
            .always(function (msg) {
                resolve(msg);
            });
    });
}

$(() => {
    $('#login').on('click', function() {
        login().then((data) => {
            if(data.response.success) {
                window.location = data.response.link;
                /* console.log(data); */
            } else {
                swal({
                    title: "Error",
                    text: data.response.outMessage,
                    icon: "error"
                })
            }
        });
    })
    $('#password').on('keypress', function(e) {
        if(e.key == 'Enter') {
            login().then((data) => {
            if(data.response.success) {
                window.location = data.response.link;
                /* console.log(data); */
            } else {
                swal({
                    title: "Error",
                    text: data.response.outMessage,
                    icon: "error"
                })
            }
        });
        }
    })
});